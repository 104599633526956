.summery-home {
  display: inline-block;
  position: relative;
  color: #fff;

}

.header {
  font-family: 'fiction-medium', serif !important;
}
.filled-button{
  background-color: #d76713 !important;
  color: #ffffff !important;
  &:hover {
    animation: shake 0.5s !important;
    animation-iteration-count: infinite;
  }
}

