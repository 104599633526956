@font-face {
  font-family: 'fiction-medium';
  src: url(./style/fonts/neosans-medium-webfont.woff) format('woff2'),
  url(./style/fonts/neosans-medium-webfont.woff) format('woff');
}

@font-face {
  font-family: 'fiction-light';
  src: url(./style/fonts/proximanova-light-webfont.woff) format('woff2'),
  url(./style/fonts/proximanova-light-webfont.woff) format('woff');
}

@import "./style/animations.scss";
@import "style/section.scss";
@import "style/colors.scss";
@import "style/other.scss";

@media only screen and (min-width: 768px) {
  body {
    height: 100%;
    overflow: hidden;
  }
}


html, body {
  font-family: "fiction-medium", Arial, Helvetica, Sans-Serif, serif !important;
  background-color: #14151a !important;

  .hidden {
    display: none !important;
  }
}

.swal2-shown {
  height: 100% !important;
}

body::before {
  content: 'mobile';
  display: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

::-webkit-scrollbar-button {
  width: 8px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #7d7d7d;
  border: 1px solid #888;
}

::selection {
  background: #607d8b;
  color: white;
}

.ball {
  position: absolute;
  border-radius: 100%;
  opacity: 0.7;
  cursor: pointer;
  transition: .10s ease-in-out;
  animation-duration: 4s;
  animation-direction: alternate;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  overflow: hidden;
  .techImage {
    display: none;
  }

  &:hover:not(.noExpand)  {
    height: 50px !important;
    width: 50px !important;

    .techImage {
      display: inline;
    }
    transform: translate(10, 50);
  }
}

@media only screen and (min-width: 768px) {
  body::before {
    content: 'desktop';
  }
}

.cording {
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
  padding: 10px;
  color: #f0f0f0;
  font-size: 1.1rem !important;
  line-height: 1.40em;
  font-weight: 500;
  overflow: hidden;
  transition: all 0.5s ease-out;
  text-align: left;
  max-width: 100%;
  position: fixed;
  bottom: 50px;

  .code-body {
    white-space: pre;
    text-align: left;
  }

  .typed-cursor {
    opacity: 1;
    font-weight: 100;
    animation: 1s blink step-end infinite;
  }

  background-color: rgba(0, 0, 0, 0.4);
}

.hljs {
  padding: 0;
  background-color: transparent;
  display: inline;
}

@keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: #ccc;
  }
}
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
