@media only screen and (min-width: 768px) {
  body::before {
    content: 'desktop';
  }
  .cd-section {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  .active-section{
    visibility: visible;
    z-index: 1;
  }

}

.pt-perspective {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  perspective: 1200px;
}

.pt-page {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  background-color: #14151a !important;
  &.details-section{
    background-color: #343a40 !important;
    overflow-y: auto;
  }
}

.pt-page-current,
.no-js .pt-page {
  visibility: visible;
  z-index: 1;
}

.blur{
  filter: blur(4px);
}

.no-js body {
  overflow: auto;
}

.pt-page-ontop {
  z-index: 999;
}


.swal-overlay{
  .swal-modal{
    width: 80%;
    .swal-title{
      font-family: fiction-medium;
    }
  }

}
