.bg-light-brown{
  background-color: #d76713;
}
.bg-light-blue{
  background-color: #bdcbb6;
}
.bg-dark-blue{
  background-color: #778472;
}


